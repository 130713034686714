import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, forwardRef } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import lodash from "lodash";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { UserService } from '../user/user.service';
import { map, take } from 'rxjs/operators';
import { CustomTokenReqType, OrgMgrStatus, Role } from '../../constants/enums';
import { ToDoManager } from '../../interface/to-do-manager';
// import { AuthProvider } from '../../providers/auth/auth';
import { Router } from '@angular/router';
import { CommonServiceService } from '../common-service.service';
import { CacheKeys, CacheService } from '../cache/cache.service';
import { ApiHelperService, CloudFnNames } from '../apiHelperService/api-helper.service';
import { environment } from '../../../../environments/environment.stage';
import { ToDoManagerClass, ToDoManagerr, ToDoStatus } from '../../constants/enums';
import { AuthService } from '../auth/auth.service';
import { ZohoService } from '../zoho/zoho.service';
import { EncryptionService } from '../encryption/encryption.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeammateService implements ToDoManager{

  constructor(
    private http: HttpClient,
    private db: AngularFireDatabase,
    private storageFirebase: AngularFireStorage,
    @Inject(forwardRef(() => UserService)) public user,
    @Inject(forwardRef(() => AuthService)) public authProvider,
    @Inject(forwardRef(() => CommonServiceService)) public commonFun,
    @Inject(forwardRef(() => CacheService)) public cacheService,
    @Inject(forwardRef(() => ApiHelperService)) public apiHelperService,
    @Inject(forwardRef(() => ZohoService)) public zohoProvider,
    private encryptionService: EncryptionService,
    private router: Router,
  ) {
  }

  orgSecDataBaseURL = environment.firebaseFunctionsBaseUrl;
  readonly secKey = environment.edk;
  tagPrefixStr = "%recipient.";

  async getStudioKey() {
    return await this.http
      .post(`${this.orgSecDataBaseURL}/createOrgManagerkey`, {})
      .toPromise();
  }

  /**
   * Get studio/teammate secure information
   * @param tmID teammate Id
   */
  async getTeammateSecData(tmID: string) {
    if(!tmID) return null;

    let snapshot = await this.db.object(`studios-sec/${tmID}/admin/email`).query.once('value')
    return snapshot.val();
  }

  async removeTeammateSecData(tmID: string) {
    if(!tmID) return;
    return await this.db.object(`studios-sec/${tmID}/`).remove();
  }


  // --- get teammate licenses list
  getTeammateLicense(uid: string) {
    return this.db.list(`/licenses/instances/${uid}`);
  }
  getOrgManagerTypes() {
      return this.db.list('/org-manager-types').snapshotChanges().pipe(
        map(changes =>
          changes.map((c:any) => ({ key: c.payload.key, ...c.payload.val() }))
        )
      )
}

  // --- delete District
  async deleteDistrict(id: string, firebaseUID?: string) {
    if (!id && !firebaseUID) throw Error("params missing!");
    let deletePromises = [];
    if (firebaseUID)
      deletePromises.push(this.user.removeUser(firebaseUID));
    deletePromises.push(this.removeTeammateSecData(id));
    deletePromises.push(this.removeOrgManagerData(id));
    return await Promise.all(deletePromises);
  }

  // --- delete studio
  deleteStudio(id:string) {
    let deletePromises = [];
    deletePromises.push(this.db.object(`/studios/${id}`).remove())
    deletePromises.push(this.removeTeammateSecData(id));
    return Promise.all(deletePromises);
  }

  // --- create studio
  createStudio(studioName, id:string) {
    return this.db.object("/studios/" + id).set(studioName);
  }

  // --- update studio
  updateStudio(studio: any, key: any) {
    return this.db.object(`/studios/${key}`).update(studio);
  }

  // get teammate
  getTeammateValue(key) {
    return this.db.object(`/studios/${key}`).valueChanges()
  }

  addEditOrgPrintSetup(data: any, key: any) {
    return this.db.object(`/organizations/${key}/settings/orderPrintSetup/defaultPrint`).update(data);
  }

  getTeamMPrintPriceSetup(key) {
    return new Promise((resolve,reject)=>{
      try{
        this.db.object(`/studios/${key}/settings/orderPrintSetup/defaultPrice`).valueChanges().subscribe({
          next: (value: any) => {
            resolve(value);
          }
        });
      }catch(e){
        reject();
        console.log(e)
      }
    })
  }
  getTeamMPrintSetup(key) {
    return new Promise((resolve,reject)=>{
      try{
        this.db.object(`/studios/${key}/settings/orderPrintSetup/defaultPrint`).valueChanges().subscribe({
          next: (value: any) => {
            resolve(value);
          }
        });
      }catch(e){
        reject();
        console.log(e)
      }
    })
  }

  getTeammate(key) {
    return new Promise((resolve, reject) => {
      try {
        let sub = this.getTeammateValue(key).subscribe({
          next: res => {
            sub.unsubscribe();
            if (res) {
              let obj = this.copyInto(res);
              if (Role.ORG == this.user.role) {
                let sub = this.db.object(`organizations/${this.user.orgID}`).valueChanges().subscribe({
                  next: res1 => {
                    sub.unsubscribe();
                    if (res1) {
                      let studioID = res1["studioID"];
                      this.getStudioLogo(studioID, url => {
                        obj.logoURL = url;
                        resolve(obj)
                      });
                    }
                  }
                })
              } else {
                this.getStudioLogo(key, (url) => {
                  if (url) {
                    obj.logoURL = url;
                    resolve(obj)
                  } else {
                    resolve(obj)
                  }
                })
              }
            } else {
              resolve('')
            }
          }
        })
      } catch (e) {
        resolve('')
      }
    })
  }

  // --- read org manager data
  async getOrgMgrData(
    studioID: string,
    propsToReturn: string[] = ["all"],
    useCache: boolean = false
  ) {
    if (!studioID || !propsToReturn) throw new Error("Params missing!");

    // --- prepare cache path
    let cachePath = `${studioID}/`;
    cachePath += this.commonFun.getCachePathForProps(propsToReturn)

    let studioData: any;

    // --- read from cache
    if (useCache) {
      let cachedData = this.cacheService.get(CacheKeys.StudioData, cachePath);
      if (cachedData) studioData = cachedData.value;
    }

    // --- read org data from database
    if(!studioData) {
      let reqBody = { studioID, propsToReturn };
      let orgMgrDataRes = await this.apiHelperService.postToCloudFn(
        CloudFnNames.getOrgMgrData,
        reqBody
      );
      studioData = lodash.get(orgMgrDataRes, "result.data");

      // --- set cache
      this.cacheService.set(CacheKeys.StudioData, cachePath, studioData);
    }

    return studioData;
  }

  copyInto(obj) {
    let copyObj:any ={...obj};
    copyObj.admin_email = obj.email;
    copyObj.admin_name = obj.contactName;
    copyObj.support_name = obj.supportStaffName;
    copyObj.support_email = obj.supportEmailID;
    copyObj.support_Phone = obj.supportPhoneNo;
    copyObj.displayName = obj.studioName;
    copyObj.website = obj.webPage;
    copyObj.logoURL = '';
    copyObj.salesTaxID = obj.salesTaxID;
    copyObj.adminPhone = obj.adminPhone;
    return copyObj;
  }
  getStudioLogo(studioID: string, callback: Function) {
    if (!studioID) {
      callback();
      return;
    }
    let studioLogoRef = "photos/studios/" + studioID + "/studioLogo";

    const imageRef = this.storageFirebase.ref(studioLogoRef);

    const subscription = imageRef
      .getDownloadURL()
      .toPromise()
      .then(url => {
        callback(url);
      })
      .catch((e) => {
        callback();
      });
  }
  // --- remove studio
  removeStudio(key: string, userKey: string) {
    if (lodash.isString(key) === true) {
      // remove org from DB
      this.db.object(`/studios/${key}/`).set(null);
      this.removeTeammateSecData(key);
      if(userKey) this.user.removeUserData(userKey);
      this.db.object(`/licenses/instances/${key}`).set(null);

      // remove user account, TODO, bug due to AngularFireAuth

      // remove image files
      let studioRef = `/photos/studios/${key}/studioLogo`;
      this.storageFirebase.ref(studioRef).getDownloadURL().toPromise().then(ref => {
        const imageRefStudio = this.storageFirebase.ref(studioRef);
        try {
          imageRefStudio.delete();
        } catch (error) {}
      }, err => {
      }).catch(e => console.log("e", e))
    }
  }

  async changeOrgMgrStatus(orgMgrID: string, orgMgrData: any, status: OrgMgrStatus) {
    if (!orgMgrID || !orgMgrData || !status)
      throw new Error("Params missing while changing org manager status");

    if (status != OrgMgrStatus.ACTIVE)
      throw new Error(
        `Changing org manager status to "${status}" is unimplemented`
      );

    // --- update status in db
    await this.updateStudio({ status: status }, orgMgrID);
    this.user.studio.status = status

    // --- send email to superadmin
    // --- mailgun post data
    const mailgunObj = {
      from: environment.mailFromAddress,
      to: [environment.onrampSetupAdminEmail],
      text: `The teammate ${orgMgrData.studioName} is now active`,
      subject: `Org Manager ${orgMgrData.studioName} is now active`,
      ["v:is_from_test"]: !environment.production,
    };
    let sendMailToSAPromise = this.http
      .post(`${environment.apiBaseUrl}/${environment.mailgunObj.endPoint}`, {
        data: [mailgunObj],
        type: "single",
        emailType: "email",
      }).pipe(take(1))
      .toPromise();

    // --- send email to org manager
    // --- mailgun post data
    if(!orgMgrData.email) {
      throw new Error("orgMgrData missing email while changing status");
    }
    const orgMgrMailgunObj = {
      from: environment.mailFromAddress,
      to: [orgMgrData.email],
      text: `Thank you for joining the team.\nYour account is stocked with a prepaid balance; you can now allocate licenses to organizations.\nFeel free to involve us when talking to prospects; your success is ours.\nOnwards & upwards!\n\n--The High5.ID Team--`,
      subject: `You are now completely ready to go as a High5.ID teammate`,
      ["v:is_from_test"]: !environment.production,
    };
    let sendMailToOrgMgrPromise = this.http
      .post(`${environment.apiBaseUrl}/${environment.mailgunObj.endPoint}`, {
        data: [orgMgrMailgunObj],
        type: "single",
        emailType: "email",
      })
      .pipe(take(1))
      .toPromise();

    try {
      await Promise.all([
        sendMailToSAPromise,
        // sendNotificationToSAPromise,
        sendMailToOrgMgrPromise
      ])
    } catch(notifyingToSAAndOrgMgrErr) {
      console.log("Error while notifying to superadmin & org mgr about org mgr status update", notifyingToSAAndOrgMgrErr);
    }
  }

  // --- get list of orgs who is inheriting data from teammate
  async getTeammateOrgs(teammateID) {
    let snapshot = await this.db.list(`organizations/`).query.orderByChild("studioID").equalTo(teammateID).once('value');
    let orgsOfTeammate = snapshot.val();
    orgsOfTeammate = lodash.map(orgsOfTeammate, (data: any, key) => {
      return { ...data, key }
    })
    return orgsOfTeammate;
  }

  // --- check to do task status
  async checkToDoStatus(task: any): Promise<ToDoStatus> {
    let manager = lodash.get(task, "manager");
    let errClass = lodash.get(task, "class");
    let privateDetails = lodash.get(task, "privateDetails");

    if (!manager || !errClass || !privateDetails || manager != ToDoManagerr.ORG_MANAGER)
      return ToDoStatus.UNKNOWN;

    let studioID = privateDetails.studioID;
    let studioData;

    switch (errClass) {
      case ToDoManagerClass.ORG_MANAGER.SETUP:
        if (!studioID) return ToDoStatus.UNKNOWN;

        // --- read studio data
        studioData = await this.commonFun.getStudio(studioID)

        // --- check for missing fields
        let requiredProps = [
          "contactName",
          "contactEmail",
          "adminPhone",
          "billingCountry",
          "billingState",
          "billingCity",
          "billingAddress",
          "billingPostCode",
          "zohoCustomerID"
        ]
        let isAnyPropMissing = lodash.some(requiredProps, prop => !lodash.has(studioData, prop))
        if(isAnyPropMissing) return ToDoStatus.ACTIVE;

        // --- check if credit card details added
        let readCardRes: any = await this.zohoProvider.readCustomerCards(studioData.zohoCustomerID)
        if(!readCardRes) return ToDoStatus.ACTIVE;
        let cardDetails = this.zohoProvider.getLatestCard(readCardRes.data);
        if(!cardDetails) return ToDoStatus.ACTIVE;

        return ToDoStatus.COMPLETED;

      case ToDoManagerClass.ORG_MANAGER.AGREEMENT:
        if (!studioID) return ToDoStatus.UNKNOWN;

        // --- read studio data
        studioData = await this.commonFun.getStudio(studioID)

        // --- check for missing fields
        let contracts = lodash.get(studioData, "settings.Legal.Contracts");
        if(!contracts) return ToDoStatus.ACTIVE;

        return ToDoStatus.COMPLETED;
      default:
        return ToDoStatus.UNKNOWN;
    }
  }

  // --- what to do on click of to-do task
  respondToClick(task: any): void {
    let manager = lodash.get(task, "manager");
    let errClass = lodash.get(task, "class");
    let privateDetails = lodash.get(task, "privateDetails");
    if (manager != ToDoManagerr.ORG_MANAGER || !errClass || !privateDetails) return;

    switch (errClass) {
      case ToDoManagerClass.ORG_MANAGER.SETUP:
      case ToDoManagerClass.ORG_MANAGER.AGREEMENT:
        let pageToOpen = lodash.get(privateDetails, "pageToOpen");
        if (pageToOpen) this.router.navigate([`/${pageToOpen}`])
        break;
    }
  }

  getUserSecData(studioId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let orgSecSub = this.db.list(`/studios-sec/${studioId}/users`).snapshotChanges().subscribe({
        next: list => {
          this.commonFun.unSub(orgSecSub);
          let userSecList: any[] = [];
          if (list.length > 0) {
            lodash.each(list, entry => {
              let value: any = entry.payload.val();
              value.key = entry.payload.key;
              userSecList.push(value);
            })
          }
          resolve(userSecList);
        }
      })
    })
  }

  async updateSecData(studioId: string, authId: string, obj: any) {
    if(obj.hasOwnProperty('email') && obj.email) {
      await this.addUserSecDataInStudioSecNode(studioId, authId, {email: obj.email})
    }
  }

  // add org secure data in database
  addUserSecDataInStudioSecNode(orgId: string, authId: string, data: any) {
    return this.db.object(`/studios-sec/${orgId}/users/${authId}`).update(data)
  }

    // --- remove  org manager data data
    async removeOrgManagerData(ID: string) {
      if (!ID) return null;
      let requestBody = JSON.stringify({ ID: ID });
      let encryptedBody = this.encryptionService.encrypt(
        requestBody,
        this.secKey
      );
      return await lastValueFrom(this.http
        .post(`${this.orgSecDataBaseURL}/removeOrgManagerData`, encryptedBody))
    }

    async updateOrgManagerLogo(ID: string, studioLogoRef) {
      if (!ID || !studioLogoRef) return null;
      let requestBody = JSON.stringify({ ID: ID, studioLogo: studioLogoRef });
      let encryptedBody = this.encryptionService.encrypt(
        requestBody,
        this.secKey
      );
      return await this.http
        .post(`${this.orgSecDataBaseURL}/updateOrgManagerLogo`, encryptedBody)
        .toPromise();
    }

    createUpdateSetting(data: any, path: string = null) {
      return this.db.object(path).update(data);
    }

    getSetting(studioId: string){
      return this.db.object(`/portal/studioRep/${studioId}`).query.once('value');
    }

    deleteSetting(studioId: string, settingId: string){
      return this.db.object(`portal/studioRep/${studioId}/${settingId}`).set(null);
    }

    async getRepresentative(schoolData?) {
      try {
        const rep = await this.getSetting(this.user.studioID);
        const showRepresentative = rep.val() ? this.commonFun.convertObjToArr(rep.val()) : [];
        let representativeList = await Promise.all(showRepresentative.map(async (rep) => {
          rep.fullName = `${rep?.meta?.firstName || ''} ${rep?.meta?.lastName || ''}`;

          let orgRep = null;
          if (!lodash.isEmpty(schoolData) && !!schoolData?.key) {
            orgRep = (await this.db.object(`/organizations/${schoolData.key}/studioRepresentatives/`).query.orderByKey().equalTo(rep.key).once('value')).val();
          }
          if (!!orgRep) {
            orgRep = Object.values(orgRep)[0];
            rep.isVisibleToSchool = orgRep.permissions.isVisibleToSchool || false;
            rep.isPrimaryContact = orgRep.permissions.isPrimaryContact || false;
            rep.role = lodash.get(orgRep, 'role', rep?.meta?.role || '');
          } else {
            rep.isVisibleToSchool = false;
            rep.isPrimaryContact = false;
            rep.role = (rep?.meta?.role || '');
          }
          return rep;
        }));
        return representativeList;
      } catch (error) {
        console.error('Error fetching representative:', error);
        return error;
      }
    }

  async addRepresentativeInStudio() {
    let rep = await this.getSetting(this.user.studioID);
    let representativesList = rep.val()
      ? this.commonFun.convertObjToArr(rep.val())
      : [];

    let isAdminRepList = lodash.filter(representativesList, (rep: any) => rep.hasOwnProperty('isMainAdmin') || rep.hasOwnProperty('isSupportStaff'))
    if (representativesList.length == 0 || isAdminRepList.length == 0) {
      let mainAdmin = {
        "meta": {
          "createdAt": this.user.studio?.createdAt,
          "email": lodash.get(this.user.studio, 'contactEmail', 'email'),
          "firstName": lodash.get(this.user.studio, 'contactName', false) ? lodash.split(this.user.studio.contactName, ' ')[0] : 'Unnamed',
          "lastName": lodash.get(this.user.studio, 'contactName', false) ? lodash.split(this.user.studio.contactName, ' ').slice(1).join(' ') : 'Representative',
          "phone": lodash.get(this.user.studio, 'adminPhone', ''),
          "role": "Admin",
        },
        "key": this.commonFun.createFirebasePushId(),
        isMainAdmin: true,
        settings: {
          visuals: {
            contactImage: null,
          },
        },
      }
      representativesList.push(mainAdmin);
      if (lodash.get(this.user.studio, 'supportEmailID', false) || lodash.get(this.user.studio, 'supportPhoneNo', false)) {
        let supportStaff = {
          "meta": {
            "createdAt": this.user.studio.createdAt,
            "email": lodash.get(this.user.studio, 'supportEmailID', ''),
            "firstName": lodash.get(this.user.studio, 'supportStaffName', false) ? lodash.split(this.user.studio.supportStaffName, ' ')[0] : 'Unnamed',
            "lastName": lodash.get(this.user.studio, 'supportStaffName', false) ? lodash.split(this.user.studio.supportStaffName, ' ').slice(1).join(' ') : 'Representative',
            "phone": lodash.get(this.user.studio, 'supportPhoneNo', ''),
            "role": "Admin",
          },
          "key": this.commonFun.createFirebasePushId(),
          isSupportStaff: true,
          settings: {
            visuals: {
              contactImage: null,
            },
          },
        }
        representativesList.push(supportStaff);
      }
    }
    this.db.object(`portal/studioRep/${this.user.studioID}/`).update(this.commonFun.convertArrToObj(representativesList));
  }
}
