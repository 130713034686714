import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { ZohoApiType } from "../../constants/enums";
import lodash from "lodash";
import moment from "moment";
import { CommonServiceService } from "../common-service.service";
import { environment } from "../../../../environments/environment.stage";


@Injectable({
  providedIn: 'root'
})
export class ZohoService {
  constructor(
    private http: HttpClient,
    @Inject(forwardRef(() => CommonServiceService)) public commonFun,
  ) {}

  // get customer cards
  readCustomerCards(customerId: string) {
    let requestBody = {
      type: ZohoApiType.GET_CUSTOMER_CARDS,
      customerID: customerId,
    };

    // --- make api call
    return this.getZohoResponse(requestBody);
  }

  // delete card from account
  removeCard(customerId: string, cardId: string) {
    let requestBody = {
      type: ZohoApiType.DELETE_CUSTOMER_CARD,
      customerID: customerId,
      cardID: cardId,
    };

    return this.getZohoResponse(requestBody);
  }

  async getCredit(customerId: string) {
    let balance: number = 0;
    let response = await this.getCreditNotes(customerId);
    if (response.status == 200) {
      if (response.data.length > 0) {
        response.data.forEach((element) => {
          balance += Number(element.balance);
        });
      }
    } else {
      throw new Error(response.message);
    }
    return balance;
  }

  // Get Credit Notes
  getCreditNotes(customerId: string) {
    let requestBody = {
      type: "getCreditNotes",
      customerID: customerId,
    };

    return this.getZohoResponse(requestBody);
  }

  // create subscription
  createSubscription(customerID: string, subscriptionData: any) {
    let requestBody = {
      type: "createSubscription",
      customerID: customerID,
      subscriptionData: subscriptionData,
    };

    return this.getZohoResponse(requestBody);
  }

  async getZohoResponse(body: any) {
    // --- make api call
    let [response, error] = await this.commonFun.executePromise(
      this.http.post(`${environment.zohoBooksV2BaseUrl}/api.php`, body).toPromise()
    );
    if (error) {
      console.log("Zoho api error", error.error);
      return error.error;
    }

    return response;
  }

  // --- from zoho cards list, get the latest card
  getLatestCard(cardsList) {
    return lodash
      .chain(cardsList)
      .orderBy((cardData) => {
        return moment(cardData.created_time, "YYYY-MM-DDTHH:mm:ssZZ").valueOf();
      }, "desc")
      .first()
      .value();
  }
}

export class InvoiceItem {
  ID: string;
  Description: string;
  Qty: number;
  UnitPrice: number;
  TaxAmount: number;
  High5ProductID: string;
}
export class Address {
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
}
